import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingLocation: false,
  isLoadingPop: false,
  isLoadingComp: false,
  error: false,
  places: [],
  vkplaces: [],
  place: null,
  cplace: null,
  hasMore: true,
  index: 0,
  actic: [],
  step: 11,
  spaces: [],
  properties: [],
  traffic: [],
  population: [],
  consumtion: null
};

const slice = createSlice({
  name: 'place',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingComp(state) {
      state.isLoadingComp = true;
    },
    startLoadingLoc(state) {
      state.isLoadingLocation = true;
    },
    startLoadingPop(state) {
      state.isLoadingPop = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getActicSuccess(state, action) {
      state.isLoading = false;
      state.actic = action.payload;
    },
    // GET POSTS
    getMyPlacesSuccess(state, action) {
      state.isLoading = false;
      state.places = action.payload;
    },
    // GET POSTS
    getPlacesSuccess(state, action) {
      state.isLoading = false;
      state.places = action.payload;
    },
    // GET POSTS
    getVkPlacesSuccess(state, action) {
      state.isLoading = false;
      state.vkplaces = action.payload;
    },
    // GET POSTS
    getVkPlaceSuccess(state, action) {
      state.isLoading = false;
      state.isLoadingLocation = false;
      state.place = action.payload;
    },

    // GET POST INFINITE
    getPlacesInitial(state, action) {
      state.isLoading = false;
      state.places = action.payload;
    },

    getMorePlaces(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    getPlaceSpaces(state, action) {
      state.isLoading = false;
      state.spaces = action.payload;
    },

    getPlaceProperties(state, action) {
      state.isLoading = false;
      state.spaces = action.payload;
    },
    getPlaceTraffic(state, action) {
      state.isLoading = false;
      state.isLoadingPop = false;
      state.traffic = action.payload;
    },

    getPlacePopulation(state, action) {
      state.isLoadingPop = false;
      state.population = action.payload;
    },
    noHasMore(state) {
      state.hasMore = false;
    },

    setPlaceSuccess(state, action) {
      state.place = action.payload;
      state.isLoadingLocation = false;
    },

    // GET PLACE
    getPlaceSuccess(state, action) {
      state.place = action.payload;
      state.isLoadingLocation = false;
    },

    // GET COMP PLACE
    getCplaceSuccess(state, action) {
      state.cplace = action.payload;
      state.isLoadingComp = false;
    },
    // GET CONSUMPTION
    getConsumtionSuccess(state, action) {
      state.isLoading = false;
      state.consumtion = action.payload.consumtion;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePlaces } = slice.actions;

// ----------------------------------------------------------------------

export function getAllPlaces() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/index');
      dispatch(slice.actions.getPlacesSuccess(response.data));
      if (response?.data?.length) {
        dispatch(getPlace(response.data[0].id));

      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSamplePlaces() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/sample');
      console.log('sample', response.data);
      dispatch(slice.actions.getPlacesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function setPlace(place) {
  return async (dispatch) => {
    //dispatch(slice.actions.startLoadingLoc());
    dispatch(slice.actions.setPlaceSuccess(place));
  };
}

// ----------------------------------------------------------------------

export function setCplace(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingComp());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/place', {
        params: { id }
      });
      dispatch(slice.actions.getCplaceSuccess(response.data.place));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getMyPlaces(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/myindex', {
        params: {
          id
        }
      });
      console.log('places my', response.data);
      dispatch(slice.actions.getMyPlacesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getMyDeepPlaces(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/mydeepindex', {
        params: {
          id
        }
      });
      console.log('places my deep', response.data);
      dispatch(slice.actions.getMyPlacesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getVkPlaces() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/vkplaces/index');
      dispatch(slice.actions.getVkPlacesSuccess(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPlacesInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/places/indexinit',
        params: {
          index,
          step
        }
      });
      // const responseai = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/openaitest');
      // console.log('ai');

      const results = response.data.places.length;
      const { maxLength } = response.data;
      dispatch(slice.actions.getPlacesInitial(response.data.places));
      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPlace(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingLoc());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/place', {
        params: { id }
      });
      dispatch(slice.actions.getPlaceSuccess(response.data.place));
      console.log('getting place response data,', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getVkPlace(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/vkplaces/vkplace', {
        params: { id }
      });
      dispatch(slice.actions.getVkPlaceSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getPlaceSpaces(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/findspaces', {
        params: { id }
      });
      dispatch(slice.actions.getPlaceSpaces(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getPlaceProperties(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/findproperties', {
        params: { id }
      });
      dispatch(slice.actions.getPlaceProperties(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------
export function getActic() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/actic');
      dispatch(slice.actions.getActicSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function getPlacePopulation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingPop());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/getpopulation', {
        params: { id }
      });
      dispatch(slice.actions.getPlacePopulation(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getPlaceTraffic(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/gettraffic', {
        params: { id }
      });
      dispatch(slice.actions.getPlaceTraffic(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
